<template>
  <Section style="padding-bottom: 0;">
    <Container>
      <div class="title-bar margin-bottom">
        <h2>{{pageTitle}} <span v-if="trials && trials.length">- {{ `Nombre d'essais : ${trialsSelected?.length}&nbsp;/&nbsp;${trials.length}` }}</span></h2>
      </div>
    </Container>
    <div class="grid-50-50">
      <!--  LA LISTE -->
      <div class="col">
        <List isGrouping disableActions disableGroupActions :api="list.api" :header="list.headers" :items="list.col"
          :item-key="list.key" :target="list.target" @selected-items="setEssais($event)"
          :default-selected-items="groupingProp ? trialsSelected : null"
          @list-send-data="setEssais($event)"
          >
          <template v-slot:annee="{ item }">
            {{ item.protocole?.annee_recolte?.valeur }}
          </template>

          <template v-slot:cultures="{ item }">
            <template v-if="item.protocole?.cultures">
              <div class="tags">
                <MiniTag
                  v-for="culture in item.protocole?.cultures"
                  :key="culture.id"
                  :id="culture.uid"
                  :text="culture.nom"
                />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:entite="{ item }">
            {{ item.protocole?.entite?.nom }}
          </template>
        </List>
      </div>
      <!-- LES FILTRES -->
      <div class="col">
        <Accordion :panels="accordion.panels">
          <template v-slot:generalites>
            <div class="grid">
              <div class="tmp-col">
                <!-- Année -->
                <SelectExtended label="Années" :items="filters.items['in%protocole%annee%id']" optionKey="id"
                  optionValue="valeur" multiple v-model="filters.filters.annee.values" @update:modelValue="updateFilters()"
                  chips />
              </div>
              <div class="tmp-col">
                <!-- Coopérative -->
                <Checkbox v-if="filters.items['in%utilisateur%entite%id']" label="Coopérative" id="cooperative"
                  :items="filters.items['in%utilisateur%entite%id']" v-model="filters.filters.cooperative.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Départements -->
                <SelectExtended label="Département" :items="filters.items['ilk%parcelle%cp']" optionKey="key"
                  optionValue="value" multiple v-model="filters.filters.departement.values"
                  @update:modelValue="updateFilters()" chips />
              </div>
              <div class="tmp-col">
                <!-- Validation agronomique -->
                <Radio label="Validation agronomique" id="validation_agro" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.validation_agro.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Fournisseurs -->
                <SelectExtended label="Fournisseur" :items="filters.items['in%protocole%fournisseur%id']" optionKey="id"
                  optionValue="nom" multiple v-model="filters.filters.fournisseur.values"
                  @update:modelValue="updateFilters()" chips />
              </div>
              <div class="tmp-col">
                <!-- Validation statistique -->
                <Radio label="Validation statistique" id="validation_statistique" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.validation_statistique.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Stress température -->
                <Checkbox v-if="filters.items['in%fiche%stress%temp%id']" label="Stress température" id="stress_temp"
                  :items="filters.items['in%fiche%stress%temp%id']" v-model="filters.filters.stress_temp.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Stress pluviométrique -->
                <Checkbox v-if="filters.items['in%fiche%stress%pluvio%id']" label="Stress pluviométrique" id="stress_pluvio"
                  :items="filters.items['in%fiche%stress%pluvio%id']" v-model="filters.filters.stress_pluvio.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- CV rendement -->
                <fieldset class="fieldset--inline">
                  <legend>CV rendement</legend>
                  <Input id="cv_from" label="De" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.cv_from.values" @update:modelValue="updateFilters()" />
                  <Input id="cv_to" label="à" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.cv_to.values" @update:modelValue="updateFilters()" />
                </fieldset>
              </div>
              <div class="tmp-col">
                <!-- Rendement moyen de l'essai -->
                <fieldset class="fieldset--inline">
                  <legend>Rendement moyen de l'essai</legend>
                  <Input id="rendement_moyen_from" label="De" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.rendement_moyen_from.values" @update:modelValue="updateFilters()" />
                  <Input id="rendement_moyen_to" label="à" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.rendement_moyen_to.values" @update:modelValue="updateFilters()" />
                </fieldset>
              </div>
            </div>
          </template>
          <template v-slot:sol>
            <div class="grid">
              <div class="tmp-col col--fullwidth">
                <!-- Type de sol -->
                <SelectExtended label="Type de sol" :items="filters.items['in%parcelle%sol%type%id']" optionKey="id"
                  optionValue="valeur" multiple v-model="filters.filters.type_sol.values"
                  @update:modelValue="updateFilters()" chips />
              </div>
              <div class="tmp-col">
                <!-- Sensibilité à la sécheresse -->
                <Radio label="Sensibilité à la sécheresse" id="secheresse" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.secheresse.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Sensibilité à l'excès d'eau -->
                <Radio label="Sensibilité à l'excès d'eau" id="exces_eau" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.exces_eau.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Irrigation -->
                <Radio label="Irrigation" id="irrigation" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.irrigation.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Drainage -->
                <Radio label="Drainage" id="drainage" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.drainage.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Sol en pente -->
                <Radio label="Sol en pente" id="sol_en_pente" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.sol_en_pente.values"
                  @update:modelValue="updateFilters()" />
              </div>
            </div>

          </template>
          <template v-slot:culture>
            <div class="grid">
              <div class="tmp-col">
                <!-- Culture -->
                <SelectExtended label="Culture" :items="filters.items['in%parcelle%culture%id']" optionKey="id"
                  optionValue="nom" multiple v-model="filters.filters.culture.values" @update:modelValue="updateFilters()"
                  chips />
                </div>
              <div class="tmp-col">
                <!-- Variété -->
                <Input label="Variété" id="variete" v-model="filters.filters.variete.values"
                  @update:modelValue="updateFilters()" />
                </div>
              <div class="tmp-col col--fullwidth">
                <!-- Date de semis -->
                <fieldset class="fieldset--inline fieldset--months">
                  <legend>Date de semis</legend>
                  <Input id="semis_from" label="De" type="month" inline :options="{ min: '2020-01' }"
                    v-model="filters.filters.semis_from.values" @update:modelValue="updateFilters()" />
                  <Input id="semis_to" label="à" type="month" inline :options="{ min: '2020-01' }"
                    v-model="filters.filters.semis_to.values" @update:modelValue="updateFilters()" />
                </fieldset>
              </div>
              <div class="tmp-col col--fullwidth">
                <!-- Densité de semis -->
                <fieldset class="fieldset--inline">
                  <legend>Densité de semis</legend>
                  <Input id="densite_semis_from" label="De" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.densite_semis_from.values" @update:modelValue="updateFilters()" />
                  <Input id="densite_semis_to" label="à" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.densite_semis_to.values" @update:modelValue="updateFilters()" />
                  <SelectExtended label="Unité" :items="filters.items['in%parcelle%unites%id']" optionKey="uid"
                    optionValue="valeur" v-model="filters.filters.densite_semis_unite.values"
                    @update:modelValue="updateFilters()" inline />
                </fieldset>
              </div>
              <div class="tmp-col col--fullwidth">
                <!-- Ecartement des rangs -->
                <fieldset class="fieldset--inline">
                  <legend>Écartement des rangs</legend>
                  <Input id="ecartement_from" label="De" type="number" inline :options="{ min: 0, step: 1 }"
                    v-model="filters.filters.ecartement_from.values" @update:modelValue="updateFilters()" />
                  <Input id="ecartement_to" label="à" type="number" inline :options="{ min: 0, step: 1 }"
                    v-model="filters.filters.ecartement_to.values" @update:modelValue="updateFilters()" />
                  <!-- Fake Select Extended -->
                  <SelectExtended label="Unité" inline style="visibility: hidden;" />
                </fieldset>
              </div>
              <div class="tmp-col col--fullwidth">
                <!-- Objectif de rendement -->
                <fieldset class="fieldset--inline">
                  <legend>Objectif de rendement</legend>
                  <Input id="objectif_rendement_from" label="De" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.objectif_rendement_from.values" @update:modelValue="updateFilters()" />
                  <Input id="objectif_rendement_to" label="à" type="number" inline :options="{ min: 0, step: 0.01 }"
                    v-model="filters.filters.objectif_rendement_to.values" @update:modelValue="updateFilters()" />
                  <SelectExtended label="Unité" :items="filters.items['in%parcelle%unites%id']" optionKey="uid"
                    optionValue="valeur" v-model="filters.filters.objectif_rendement_unite.values"
                    @update:modelValue="updateFilters()" inline />
                </fieldset>
              </div>
              <div class="tmp-col col--fullwidth">
                <!-- Date de levee -->
                <fieldset class="fieldset--inline fieldset--months">
                  <legend>Date de levée</legend>
                  <Input id="levee_from" label="De" type="month" inline :options="{ min: '2020-01' }"
                    v-model="filters.filters.levee_from.values" @update:modelValue="updateFilters()" />
                  <Input id="levee_to" label="à" type="month" inline :options="{ min: '2020-01' }"
                    v-model="filters.filters.levee_to.values" @update:modelValue="updateFilters()" />
                </fieldset>
              </div>
              <div class="tmp-col">
                <!-- Culture précédente -->
                <SelectExtended label="Culture précédente" :items="filters.items['in%parcelle%culture%id']" optionKey="id"
                  optionValue="nom" multiple v-model="filters.filters.culture_pre.values"
                  @update:modelValue="updateFilters()" chips />
              </div>
              <div class="tmp-col">
                <!-- Travail du sol -->
                <SelectExtended label="Travail du sol" :items="filters.items['in%parcelle%travail%id']" optionKey="id"
                  optionValue="valeur" multiple v-model="filters.filters.sol_travail.values"
                  @update:modelValue="updateFilters()" chips />
              </div>
              <div class="tmp-col">
                <!-- Culture en production -->
                <Radio label="Culture en production" id="culture_prod" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.culture_prod.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Engrais Minéraux -->
                <Radio label="Engrais minéraux" id="engrais_mineraux" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.engrais_mineraux.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Engrais organiques -->
                <Radio label="Engrais organiques" id="engrais_organiques" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.engrais_organiques.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Amendement calcaires -->
                <Radio label="Amendement calcaires" id="amendement_calcaires" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.amendement_calcaires.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Oligoélements -->
                <Radio label="Oligoéléments" id="oligoelements" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.oligoelements.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Herbicides -->
                <Radio label="Herbicides" id="herbicides" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.herbicides.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Insecticides -->
                <Radio label="Insecticides" id="insecticides" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.insecticides.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Fongicides -->
                <Radio label="Fongicides" id="fongicides" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.fongicides.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Régulateurs -->
                <Radio label="Régulateurs" id="regulateurs" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.regulateurs.values"
                  @update:modelValue="updateFilters()" />
              </div>
              <div class="tmp-col">
                <!-- Autres -->
                <Radio label="Autres" id="autres" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'Indifférent', value: '' },
                ]" inputStyle="inline" v-model="filters.filters.autres.values"
                  @update:modelValue="updateFilters()" />
              </div>
            </div>
          </template>
        </Accordion>
      </div>
    </div>
  </Section>
  <Loader :active="loading"/>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import List from '@/components/list/List.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import Radio from '@/components/form/Radio.vue'
import Input from '@/components/form/Input.vue'
import Accordion from '@/components/layout/Accordion.vue'
import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'GroupingEssaisAddEditView',
  components: {
    Container,
    Section,
    List,
    MiniTag,
    SelectExtended,
    Checkbox,
    Radio,
    Input,
    Accordion,
    Loader,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    groupingProp: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      grouping: this.groupingProp,
      accordion: {
        panels: [
          {
            key: 'generalites',
            title: 'Généralités',
          },
          {
            key: 'sol',
            title: 'Sol',
          },
          {
            key: 'culture',
            title: 'Cultures',
          },
        ],
      },
      filters: {
        items: {},
        filters: {
          annee: {
            type: 'in',
            parent: 'protocole.annee',
            key: 'id',
            values: [],
          },
          cooperative: {
            type: 'in',
            parent: 'technicien.entite',
            key: 'id',
            values: [],
          },
          departement: {
            type: 'ilk_multiple',
            parent: 'parcelle.cp',
            key: null,
            values: [],
          },
          validation_agro: {
            type: 'bool',
            parent: 'fiche.validation_agronomique',
            key: null,
            values: null,
          },
          validation_statistique: {
            type: 'bool',
            parent: 'fiche.validation_statistique',
            key: null,
            values: null,
          },
          fournisseur: {
            type: 'in_multiple',
            parent: 'protocole.modalite.semence.fournisseur|protocole.modalite.produit.fournisseur',
            key: 'id',
            values: [],
          },
          stress_temp: {
            type: 'in_stress',
            parent: 'fiche.stress_temperature',
            key: 'id',
            values: [],
          },
          stress_pluvio: {
            type: 'in_stress',
            parent: 'fiche.stress_pluviometrie',
            key: 'id',
            values: [],
          },
          cv_from: {
            type: 'gte',
            parent: 'fiche.cv_rendement',
            key: null,
            values: '',
          },
          cv_to: {
            type: 'lte',
            parent: 'fiche.cv_rendement',
            key: null,
            values: '',
          },
          rendement_moyen_from: {
            type: 'gte',
            parent: 'fiche.rendement_moyen',
            key: null,
            values: '',
          },
          rendement_moyen_to: {
            type: 'lte',
            parent: 'fiche.rendement_moyen',
            key: null,
            values: '',
          },
          type_sol: {
            type: 'in',
            parent: 'parcelle_fiche.sol_type',
            key: 'id',
            values: [],
          },
          secheresse: {
            type: 'bool',
            parent: 'parcelle_fiche.sensibilite_secheresse',
            key: null,
            values: null,
          },
          exces_eau: {
            type: 'bool',
            parent: 'parcelle_fiche.sensibilite_exces_eau',
            key: null,
            values: null,
          },
          irrigation: {
            type: 'bool',
            parent: 'parcelle_fiche.irrigation',
            key: null,
            values: null,
          },
          drainage: {
            type: 'bool',
            parent: 'parcelle_fiche.drainage',
            key: null,
            values: null,
          },
          sol_en_pente: {
            type: 'bool',
            parent: 'parcelle_fiche.sol_en_pente',
            key: null,
            values: null,
          },
          culture: {
            type: 'in',
            parent: 'parcelle_fiche.culture',
            key: 'id',
            values: [],
          },
          variete: {
            type: 'ilk',
            parent: 'parcelle_fiche.variete',
            key: null,
            values: '',
          },
          semis_from: {
            type: 'date_gte',
            parent: 'parcelle_fiche.date_semis',
            key: 'id',
            values: '',
          },
          semis_to: {
            type: 'date_lte',
            parent: 'parcelle_fiche.date_semis',
            key: 'id',
            values: '',
          },
          densite_semis_from: {
            type: 'gte',
            parent: 'parcelle_fiche.densitesemis',
            key: null,
            values: '',
          },
          densite_semis_to: {
            type: 'lte',
            parent: 'parcelle_fiche.densitesemis',
            key: null,
            values: '',
          },
          densite_semis_unite: {
            type: 'in',
            parent: 'parcelle_fiche.unite_densite_semis',
            key: 'uid',
            values: [],
          },
          ecartement_from: {
            type: 'gte',
            parent: 'parcelle_fiche.ecartement_rangs',
            key: null,
            values: '',
          },
          ecartement_to: {
            type: 'lte',
            parent: 'parcelle_fiche.ecartement_rangs',
            key: null,
            values: '',
          },
          objectif_rendement_from: {
            type: 'gte',
            parent: 'parcelle_fiche.objectifs_rendement',
            key: null,
            values: '',
          },
          objectif_rendement_to: {
            type: 'lte',
            parent: 'parcelle_fiche.objectifs_rendement',
            key: null,
            values: '',
          },
          objectif_rendement_unite: {
            type: 'in',
            parent: 'parcelle_fiche.unite_objectif_rendement',
            key: 'uid',
            values: [],
          },
          levee_from: {
            type: 'date_gte',
            parent: 'parcelle_fiche.date_levee',
            key: null,
            values: '',
          },
          levee_to: {
            type: 'date_lte',
            parent: 'parcelle_fiche.date_levee',
            key: null,
            values: '',
          },
          culture_pre: {
            type: 'in',
            parent: 'parcelle_fiche.culture_precedante',
            key: 'id',
            values: [],
          },
          sol_travail: {
            type: 'in',
            parent: 'parcelle_fiche.sol_travail',
            key: 'id',
            values: [],
          },
          culture_prod: {
            type: 'bool',
            parent: 'parcelle_fiche.cultureproduction',
            key: null,
            values: null,
          },
          engrais_mineraux: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.mineraux_date|parcelle_fiche.mineraux_produits',
            key: null,
            values: null,
          },
          engrais_organiques: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.organique_date|parcelle_fiche.organique_produits',
            key: null,
            values: null,
          },
          amendement_calcaires: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.calcaire_date|parcelle_fiche.calcaire_produits',
            key: null,
            values: null,
          },
          oligoelements: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.oligo_date|parcelle_fiche.oligo_produits',
            key: null,
            values: null,
          },
          herbicides: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.herbicides_date|parcelle_fiche.herbicides_produits',
            key: null,
            values: null,
          },
          insecticides: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.inscticides_date|parcelle_fiche.insecticides_produits',
            key: null,
            values: null,
          },
          fongicides: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.fongicides_date|parcelle_fiche.fongicides_produits',
            key: null,
            values: null,
          },
          regulateurs: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.regulateurs_date|parcelle_fiche.regulateurs_produits',
            key: null,
            values: null,
          },
          autres: {
            type: 'conditional_bool',
            parent: 'parcelle_fiche.autres_date|parcelle_fiche.autres_produits',
            key: null,
            values: null,
          },
        },
      },
      list: {
        target: {
          route: {
            name: 'essai',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        api: {
          endpoint: `protocole/${this.$route.params.id}/regroupement_essai/essai_regroupable`,
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          {
            label: 'Nom de l\'essai', dataField: 'nom', sorted: true, isLink: true,
          },
          { label: 'Année' },
          { label: 'Culture' },
          { label: 'Entité' },
        ],
        col: ['nom', 'annee', 'cultures', 'entite'],
        key: 'id',
      },
      endPoint: null,
      trials: [],
      trialsSelected: this.groupingProp?.essais ?? [],
      previousRouteName: null,
      routeParams: null,
      loading: !!this.groupingProp,
    }
  },
  emits: ['set-step', 'set-route', 'update:modelValue', 'set-grouping'],
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.handleValues()
      }
      this.$emit('update:modelValue', false)
    },
  },

  async mounted() {
    this.$emit('set-step', 0)
    this.$emit('set-route', {
      previousRouteName: this.previousRouteName,
      routeParams: this.routeParams,
    })
    this.filters.items = await this.groupingService.setFiltersItems()
    if (this.groupingProp) {
      this.filters.filters = JSON.parse(this.groupingProp.filtres)
      this.updateFilters()
      this.trialsSelected = this.groupingProp.essais
      this.grouping = this.groupingProp
      this.loading = false
    }
  },
  methods: {
    updateFilters() {
      this.emitter.emit('list-update-params', { filters: this.groupingService.encodeQueryParams(this.filters.filters) })
    },

    setEssais(event) {
      if (typeof (event) === 'object' && event.selected) {
        this.trialsSelected = event.selected
      } else if (typeof (event) === 'object' && event.items) {
        this.trials = event.items
      }
    },
    handleValues() {
      if (!this.trialsSelected.length) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Vous devez selectionner au moins un essai.',
        })
        this.emitter.emit('close-loader')
        return
      }

      this.emitter.emit('open-loader')
      const params = {
        essais: this.trialsSelected.map((t) => ({ id: t.id })),
        filtres: JSON.stringify(this.filters.filters),
        modalites: null,
        variables: null,
      }

      const addTrials = () => {
        this.fetchService
          .patch(
            `protocole/${this.$route.params.id}/regroupement_essai/${this.grouping.id}/essai`,
            params,
          )
          .then(
            (r) => {
              this.$emit('set-grouping', r.data)
              this.$router.push({ name: 'groupingModalitiesAdd', params: { id: this.$route.params.id, rid: this.grouping.id } })
            },
            () => {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une erreur s\'est produite lors de l\'ajout des essais.',
              })
            },
          )
          .finally(() => {
            this.emitter.emit('close-loader')
          })
      }

      const createGrouping = () => {
        this.fetchService
          .post(`protocole/${this.$route.params.id}/regroupement_essai`)
          .then(
            (response) => {
              this.grouping = response.data
              addTrials()
            },
            () => {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une erreur s\'est produite lors de la création du regroupement.',
              })
              this.emitter.emit('close-loader')
            },
          )
      }

      if (this.grouping !== null) {
        addTrials()
      } else {
        createGrouping()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

h2 {
  > span {
    font-weight: $font-weight-normal;
    text-transform: none;
  }
}

.fieldset--inline {
  display: flex;
  // flex-wrap: wrap;
  gap: $gutter-half;

  > .input-block--inline {
    // flex-basis: 49%;
    margin-bottom: 0;

    ::v-deep(> *) {
      flex-basis: auto !important;

      &.multiselect {
        min-width: 27rem;
      }
    }
  }

  &.fieldset--months {
    gap: $gutter;
    flex-wrap: wrap;

    @include bp('xs') {
      flex-wrap: nowrap;
    }

    > * {
      flex-basis: 50%;

      > ::v-deep(.input) {
        flex-grow: 1;
      }
    }
  }
}
.title-bar {

  @include bp('sm') {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h2 {
      margin-bottom: 0;
    }
  }
}

.grid-50-50 {
  border-top: 1px solid $color-gray-lighter;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: $gutter;

  @include bp('xl') {
    grid-template-columns: 1fr 1fr;
  }
}

.accordion {
  height: 80rem;
}

.list {
  overflow: auto;
  @include custom-scrollbar($color-gray);
}

::v-deep(.list-body) {
  border-top: none;
}

::v-deep(tbody) {
  overflow: auto;
  @include custom-scrollbar($color-gray);
}

::v-deep(tbody .list-row:first-child) {
  > * {
    border-top: none;
  }
}

::v-deep(.list-row--head) {
  position: sticky;
  top: 0px;
  z-index: +1;

  > * {
    background-color: white;
    border-bottom: 1px solid $color-gray-lighter;
  }
}
</style>
